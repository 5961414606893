import React from "react";
import Hero from "./Hero";
import NavBar from "./NavBar";
import PropTypes from "prop-types";

function Header(props) {
    let hero = props.withHero ? <Hero/> : "";
    let classNameString = "bg-white " + (props.withHero ? "" : "mb-20");

    return (
        <header className={classNameString}>
            <NavBar initialTransparency={props.withHero}/>

            {hero}

        </header>
    );
}

Header.propTypes = {
    withHero: PropTypes.bool
}

export default Header;
