import {Link} from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const LinkButton = (props) => {
    if (props.external) {
        return (
            <div className={"m-2 bg-ap-action-bg text-ap-action-fg hover:bg-ap-action-hover rounded p-2 px-4 shadow-lg " + props.className}>
                <a href={props.to}>
                    {props.children}
                </a>
            </div>
        )
    }
    else {
        return (
            <div className={"m-2 bg-ap-action-bg text-ap-action-fg hover:bg-ap-action-hover rounded p-2 px-4 shadow-lg " + props.className}>
                <Link to={props.to}>
                    {props.children}
                </Link>
            </div>
        )
    }

}

LinkButton.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    external: PropTypes.bool,
};

export default LinkButton;