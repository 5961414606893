import React from "react";
import PropTypes from "prop-types";

const Main = ({children}) => {
    return (
        // Full width, auto margins
        <main className="flex-0 w-full mx-auto">
            {children}
        </main>
    )
}


Main.propTypes = {
    children: PropTypes.node.isRequired,
};


export default Main;