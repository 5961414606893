import {Link} from "gatsby";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {motion, useAnimation} from "framer-motion";
import LinkButton from "./LinkButton";

const MenuLink = (props) => {
    return (
        <Link
            className="m-3 no-underline text-gray-800"
            to={props.to}
        >
            {props.children}
        </Link>
    )
}

MenuLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.node.isRequired,
};

const MenuItem = (props) => {
    return (
        <div className={"flex flex-row p-0 m-0 " + props.className ?? ""}>
            {props.children}
        </div>
    )
}

MenuItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

const Menu = (props) => {
    const controls = useAnimation()
    const [wasOpen, setWasOpen] = useState(false)

    const variants = {
        visible: { height: "auto", opacity: 1},
        middle: { height: "auto", opacity: 0},
        hidden: { height: 0, opacity: 0},
    }

    useEffect(() => {
        sequence()
    })

    const sequence = async () => {
        if (props.isOpen && ! wasOpen) {
            setWasOpen(true)
            await controls.start("middle", {duration: 0.2})
            return await controls.start("visible", {duration: 0.2})
        }
        else if (!props.isOpen && wasOpen) {
            setWasOpen(false)
            // await controls.start("middle", {duration: 0.3})
            return await controls.start("hidden",{duration: 0.01})
        }
    }

    return(
        <>
            <motion.nav className={`${props.isOpen ? `block` : `hidden`}`}
                variants={variants} initial="hidden" animate={controls}
            >
                <MenuItem className="lg:hidden justify-end"><MenuLink to="/">Home</MenuLink></MenuItem>
                <MenuItem className="lg:hidden justify-end"><MenuLink to="/about">About</MenuLink></MenuItem>
                <MenuItem className="lg:hidden justify-end"><MenuLink to="/medical-records">Medical Records</MenuLink></MenuItem>
                <MenuItem className="lg:hidden justify-end"><MenuLink to="/office">Office</MenuLink></MenuItem>
                <MenuItem className="lg:hidden justify-end"><MenuLink to="/contact">Contact</MenuLink></MenuItem>
                <MenuItem className="lg:hidden justify-end">
                    <LinkButton external={true} to="https://phr.charmtracker.com/login.sas?FACILITY_ID=a6e3869593e3ae0e2d3ebf6b2a9da63e1d15d1c82ff5a7262e62fe42203ae47d7a68c4657f2ee8b2">Patient Portal</LinkButton>
                </MenuItem>
                <MenuItem className="lg:hidden justify-end"><LinkButton to="/appointments">Appointments</LinkButton></MenuItem>
                <MenuItem className="justify-end"><MenuLink to="/consulting">Consulting</MenuLink></MenuItem>
                <MenuItem className="justify-end"><MenuLink to="/frequently-asked-questions">FAQ</MenuLink></MenuItem>
                <MenuItem className="justify-end"><MenuLink to="/feedback">Feedback</MenuLink></MenuItem>
            </motion.nav>
        </>
    )
}

Menu.propTypes = {
    isOpen: PropTypes.bool.isRequired
}

export default Menu;
export {MenuItem, MenuLink};