import React from "react";
import PropTypes from "prop-types";

const SVGCurve = (props) => {
    let color = "fill-current"
    let direction = "down"
    let bgColor = ""

    let colorMap = {'light-mid': 'text-gray-100', 'light-dark': 'text-ap-dark-bg', 'mid-light': 'text-white', 'mid-dark': 'text-ap-dark-bg',
        'dark-light': 'text-white', 'dark-mid': 'text-gray-100'}
    let bgColorMap = {'light-mid': 'bg-white', 'light-dark': 'bg-white', 'mid-light': 'bg-gray-100', 'mid-dark': 'bg-gray-100',
        'dark-light': 'bg-ap-dark-bg', 'dark-mid': 'bg-ap-dark-bg'}

    if (props.transition) {
        color = colorMap[props.transition]
        bgColor = bgColorMap[props.transition]
    }
    else {
        color = props.bottomColor ? props.bottomColor : color;
        bgColor = props.topColor ? props.topColor : bgColor;
    }

    direction = props.direction ? props.direction : direction;

    if (direction === "down") {
        return (
            <div className={color + ' ' + bgColor}>
                <svg viewBox="0 0 500 30" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none">
                    <path fill="currentColor" d="M 0 0 C 120 20 380 20 500 0 L 500 30 L 0 30 L 0 0 Z"></path>
                </svg>
            </div>
        )
    }
    else if (direction === "right-slope") {
        return (
            <div className={color + ' ' + bgColor + ' m-0 p-0'}>
                <svg viewBox="0 0 500 30" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none">
                    <path fill="currentColor" d="M 0 0 C 120 20 380 20 500 30 L 0 30 Z"></path>
                </svg>
            </div>
        )
    }
    else if (direction === "left-slope") {
        return (
            <div className={color + ' ' + bgColor + ' m-0 p-0'}>
                <svg viewBox="0 0 500 30" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none">
                    <path fill="currentColor" d="M 0 0 L 500 0 C 380 10 120 10 00 30 Z"></path>
                </svg>
            </div>
        )
    }
    else if (direction === "up") {
        return (
            <div className={color + ' ' + bgColor + ' m-0 p-0'}>
                <svg viewBox="0 0 500 30" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" preserveAspectRatio="none">
                    <path fill="currentColor" d="M 0 0 L 500 0 L 500 30 C 380 10 120 10 0 30 L 0 0  Z"></path>
                </svg>
            </div>
        )
    }
    else {
        throw new Error(`Invalid direction in ScgCurve ${direction}`);
    }
}

SVGCurve.propTypes = {
    transition: PropTypes.oneOf(['light-mid', 'light-dark', 'mid-light', 'mid-dark', 'dark-light', 'dark-mid']),
    bottomColor: PropTypes.string,
    topColor: PropTypes.string,
    direction: PropTypes.oneOf(['up', 'down', 'right-slope', 'left-slope'])
};

export default SVGCurve;

