import React from "react";
import LinkButton from "./LinkButton";
import * as heroStyles from "./hero.module.css"
import {motion, useMotionTemplate, useTransform, useViewportScroll} from "framer-motion";
import PropTypes from "prop-types";
import {graphql, StaticQuery} from 'gatsby'
import useWindowSize from "../util/useWindowSize";
import { getSrc } from "gatsby-plugin-image"

const MainHeroDiv = (props) => {
    const { width } = useWindowSize()

    const {scrollYProgress} = useViewportScroll();
    const yDelta = useTransform(scrollYProgress, value => (width > 767 ? -330 : -250) + (-260 * value))
    const backgroundPosition = useMotionTemplate`center ${yDelta}px`

    return (
        <StaticQuery
            query={
                graphql`
                query {
                    desktop: file(relativePath: { eq: "hero.background.jpg" }) {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)   
                        }
                    }
                }
            `}
            render={data => {
                const imageSrc = getSrc(data.desktop.childImageSharp.gatsbyImageData)

                return (
                    <motion.div className={heroStyles.hero} style={{backgroundPosition: backgroundPosition, backgroundImage: `url(${imageSrc})`}}>
                        {props.children}
                    </motion.div>
                )
            }}
        />
    )
}

MainHeroDiv.propTypes = {
    children: PropTypes.node.isRequired,
}

const Hero = () => {
    // TODO: Need to review the CSS on this
    return (
        // <BackgroundSection className={heroStyles.hero}>
        <MainHeroDiv>
            {/*<motion.div className="flex flex-col text-center max-w-6xl" initial={{opacity: 0}} animate={{opacity: 1}}*/}
            {/*            transition={{duration: 2}}>*/}
            <motion.div className="flex flex-col text-center max-w-6xl">
                <div className="text-ap-header text-2xl md:text-3xl font-bold">Practice Closed</div>
                <div className="text-2xl md:text-2xl md:font-normal font-semibold mt-2">Effective 1/31/23</div>
                <div className="flex flex-row mt-6 justify-center   ">
                    <LinkButton to="/practice-closure-notice">Read More
                    </LinkButton>
                </div>
                {/*<div className="md:hidden"><span className="text-4xl text-ap-action">&#9660;</span></div>*/}
            </motion.div>
        {/*</BackgroundSection>*/}
        </MainHeroDiv>
    )
}

export default Hero;