import React, {useState } from "react";
import {Link} from "gatsby";
import Menu, {MenuItem, MenuLink} from "./Menu";
import {motion, useMotionTemplate, useTransform, useViewportScroll} from "framer-motion";
import PropTypes from "prop-types";
import APLogo from "./Logo";
import LinkButton from "./LinkButton";

const MainBar = (props) => {
    const { scrollYProgress } = useViewportScroll();
    const alpha = useTransform(scrollYProgress, value => Math.min(value * 10, 1.0))
    const backgroundColor = useMotionTemplate`rgba(255,255,255,${alpha})`

    return (
        <motion.div className="fixed flex justify-center w-full bg-ap-light-bg z-50 p-2" style={{backgroundColor: props.menuOpen ? '#fff' : backgroundColor}}>
             {props.children}
        </motion.div>
    )
}

MainBar.propTypes = {
    children: PropTypes.node.isRequired,
    menuOpen: PropTypes.bool.isRequired
}

const NavBar = (props) => {
    const [isOpen, toggleOpen] = useState(false);

    return (
        <MainBar menuOpen={! props.initialTransparency || isOpen}>
            <div className="flex flex-col w-full max-w-6xl justify-between">
                <div className="flex justify-between items-center">
                    <h1 className="text-ap-header no-underline text-2xl md:text-3xl font-bold">
                        <Link to="/">
                            <APLogo uniquePrefix="logo-1" className="w-12 inline-block mb-1"/>
                            <div className="inline-block text-shadow">Agile Psychiatry</div>
                        </Link>
                    </h1>

                    <div className="flex flex-row items-center justify-between">

                            <MenuItem className="hidden lg:block"><MenuLink to="/">Home</MenuLink></MenuItem>
                            <MenuItem className="hidden lg:block"><MenuLink to="/about">About</MenuLink></MenuItem>
                            <MenuItem className="hidden lg:block"><MenuLink to="/medical-records">Medical Records</MenuLink></MenuItem>
                            {/*<MenuItem className="hidden lg:block"><MenuLink to="/office">Office</MenuLink></MenuItem>*/}
                            <MenuItem className="hidden lg:block"><MenuLink to="/contact">Contact</MenuLink></MenuItem>
                            <MenuItem className="hidden lg:block">
                                <LinkButton external={true} to="https://phr.charmtracker.com/login.sas?FACILITY_ID=a6e3869593e3ae0e2d3ebf6b2a9da63e1d15d1c82ff5a7262e62fe42203ae47d7a68c4657f2ee8b2">Patient Portal</LinkButton>
                            </MenuItem>
                            <MenuItem className="hidden lg:block"><LinkButton to="/appointments">Appointments</LinkButton></MenuItem>

                        <button
                            className="flex px-2 py-2 mr-1 rounded text-ap-header border-2 border-ap-header"
                            onClick={() => toggleOpen(!isOpen)}
                        >
                            {/* TODO: review this SVG for accessability */}
                            <svg
                                className="w-4 h-4 fill-current m-1"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title className="font-bold">Menu</title>
                                {/*<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" className="menu-button-path"/>*/}
                                <path d="M 0,3 h 20 v-2 h -20 v2 z" className="menu-button-path"/>
                                <path d="M 0,10 h 20 v-2 h -20 v2 z" className="menu-button-path"/>
                                <path d="M 0,18 h 20 v-2 h -20 v2 z" className="menu-button-path"/>
                                {/*<path d="M0 3h20v2H0V3zm0" className="menu-button-path"/>*/}
                            </svg>
                        </button>
                    </div>

                </div>
                <Menu isOpen={isOpen}/>
            </div>
        </MainBar>

    )
}

NavBar.propTypes = {
    initialTransparency: PropTypes.bool
}

export default NavBar;