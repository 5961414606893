import React from "react";
import {Link} from "gatsby";
import PropTypes from "prop-types";
import SVGCurve from "./SVGCurve";

const FooterLink = (props) => {
    return (
        <Link
            className="font-bold no-underline"
            to={props.to}
        >
            {props.children}
        </Link>
    )
}

FooterLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.node.isRequired,
};

const Footer = (props) => {
    let lastSectionColor = props.lastSectionColor ?? "mid"

    return (
        <footer>
            {lastSectionColor === "mid" || (<SVGCurve direction="down" transition={`${lastSectionColor}-mid`}/>)}
            <div className="bg-gray-100">
                <nav className="flex justify-between max-w-6xl p-4 mx-auto text-sm md:p-8 text-gray-700">
                    <p>
                    {/*Professional verification provided by Psychology Today*/}
                    <a href="https://www.psychologytoday.com/profile/832878" className="sx-verified-seal"></a>
                    <script type="text/javascript" src="https://member.psychologytoday.com/verified-seal.js" data-badge="13" data-id="832878" data-code="aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s="></script>
                    {/*End Verification*/}
                    </p>

                    <p className="font-bold">
                        ©{new Date().getFullYear()} Agile Psychiatry PLLC
                    </p>


                    <p>
                        <FooterLink
                            to="/privacy-policy"
                        >
                            Privacy Policy
                        </FooterLink>
                    </p>
                </nav>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    lastSectionColor: PropTypes.oneOf(['light','mid','dark'])
};

export default Footer;