import React from "react";
import PropTypes from "prop-types";

const mapShadeToStyle = {
    light: {
        fg: "text-ap-light-fg",
        bg: "bg-ap-light-bg"
    },
    mid: {
        fg: "text-ap-mid-fg",
        bg: "bg-ap-mid-bg"
    },
    dark: {
        fg: "text-ap-dark-fg",
        bg: "bg-ap-dark-bg",
    }
}

const Section = (props) => {
    return (
        <section className={"w-full mx-auto " + mapShadeToStyle[props.shade].bg + " " + mapShadeToStyle[props.shade].fg}>
            {props.children}
        </section>
    )
}

Section.propTypes = {
    children: PropTypes.node.isRequired,
    shade: PropTypes.oneOf(['light', 'mid', 'dark']).isRequired,
};

const SectionHeader = (props) => {
    if (props.name) {
        return (
            <h2 className="text-3xl text-center mb-2">
                <SectionNamedAnchor name={props.name}/>
                {props.children}
            </h2>
        )
    }
    else {
        return (
            <h2 className="text-3xl text-center">{props.children}</h2>
        )
    }
}

SectionHeader.propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string
};

const SectionNamedAnchor = (props) => {
    return (
        <a name={props.name} style={{display: "block", position:"relative", top: "-64px", visibility:"hidden"}}>

        </a>
    )
}

SectionNamedAnchor.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Section;
export {SectionHeader}