import React from "react";
import PropTypes from "prop-types";

const APLogo = (props) => {
    const gradientPrefix = props.uniquePrefix + "-gradient-"
    const gradient1 = gradientPrefix + "1"
    const gradient2 = gradientPrefix + "2"
    const gradient3 = gradientPrefix + "3"
    const gradient4 = gradientPrefix + "4"

    return (
        <svg viewBox="0 0 150 160" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <defs>
                <linearGradient gradientUnits="userSpaceOnUse" x1="336.704" y1="240.551" x2="336.704" y2="304.487" id={gradient1} gradientTransform="matrix(1.31603, 0.890655, -1.0195, 1.506416, -69.690895, -659.153872)">
                    <stop offset="0" style={{ stopColor: "rgb(233, 241, 254)" }}/>
                    <stop offset="1" style={{ stopColor: "rgb(114, 165, 252)"}} />
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" x1="326.345" y1="283.516" x2="326.345" y2="317.13" id={gradient2} gradientTransform="matrix(0.417595, -1.533236, 3.403964, 0.927111, -1087.622246, 329.485074)">
                    <stop offset="0" style={{ stopColor: "rgb(238, 244, 254)"}}/>
                    <stop offset="1" style={{ stopColor: "rgb(19, 37, 155)"}}/>
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" x1="326.345" y1="283.516" x2="326.345" y2="317.13" id={gradient3} gradientTransform="matrix(0.382834, -1.542286, 3.10313, 0.770277, -982.072747, 382.188626)">
                    <stop offset="0" style={{ stopColor: "rgb(227, 233, 249)"}}/>
                    <stop offset="1" style={{ stopColor: "rgb(0, 35, 109)"}}/>
                </linearGradient>
                <linearGradient gradientUnits="userSpaceOnUse" x1="320.438" y1="239.715" x2="320.438" y2="304.746" id={gradient4} gradientTransform="matrix(1.350027, -0.838229, 0.960738, 1.547339, -643.088066, -99.59772)">
                    <stop offset="0" style={{ stopColor: "rgb(226, 243, 255)"}}/>
                    <stop offset="1" style={{ stopColor: "rgb(114, 165, 252)"}}/>
                </linearGradient>
            </defs>
            <path style={{fill: "url(#" + gradient1 + ")"}} d="M 128.022 3.212 C 126.309 58.363 66.803 161.389 48.851 67.586 C 74.839 106.922 109.295 40.678 128.022 3.212 Z"/>
            <path style={{fill: "url(#" + gradient2 + ")", stroke: "url(#" + gradient3 + ")", strokeWidth: "2px"}} d="M 127.336 124.903 C 139.561 46.857 57.677 70.753 46.573 94.491 C 31.228 110.674 19.112 97.092 13.666 92.005 C 14.115 98.751 24.49 114.873 41.559 114.753 C 58.403 114.201 67.908 102.257 87.429 99.494 C 101.023 97.571 118.664 104.496 127.336 124.903 Z"/>
            <path style={{fill: "url(#" + gradient4 + ")"}} d="M 20.219 1.885 C 26.994 26.459 53.081 113.786 104.402 77.472 C 71.911 147.675 9.849 70.624 20.219 1.885 Z"/>
        </svg>
    )
}

APLogo.propTypes = {
    className: PropTypes.string.isRequired,
    uniquePrefix: PropTypes.string.isRequired,
}

export default APLogo;